import { getMapResults } from "./dynamic-search.js";
import { default as PopupOverlay } from "./overlay-popup.js";
import { default as helpPinInit } from "./help-pin.js";
import { hideLoader, showLoader } from "./utilities.js";

let map,
	data,
	Popup,
	currentPopup,
	currentPopupMarker,
	popupVisible,
	markers = [],
	markerCluster,
	advancedMarker;

const openPin = '/images/common/map-pin-selected.png',
	closePin = '/images/common/map-pin.png',
	clusterPin = '/images/common/map-pin-cluster.png',
	overlayOpenCSSClass = 'map-card-overlay-open',
	apiKey = 'AIzaSyDvBaW1r7UYqTnilDpKxQEETmqqSV86oYY';

(g => { var h, a, k, p = "The Google Maps JavaScript API", c = "google", l = "importLibrary", q = "__ib__", m = document, b = window; b = b[c] || (b[c] = {}); var d = b.maps || (b.maps = {}), r = new Set, e = new URLSearchParams, u = () => h || (h = new Promise(async (f, n) => { await (a = m.createElement("script")); e.set("libraries", [...r] + ""); for (k in g) e.set(k.replace(/[A-Z]/g, t => "_" + t[0].toLowerCase()), g[k]); e.set("callback", c + ".maps." + q); a.src = `https://maps.${c}apis.com/maps/api/js?` + e; d[q] = f; a.onerror = () => h = n(Error(p + " could not load.")); a.nonce = m.querySelector("script[nonce]")?.nonce || ""; m.head.append(a) })); d[l] ? console.warn(p + " only loads once. Ignoring:", g) : d[l] = (f, ...n) => r.add(f) && u().then(() => d[l](f, ...n)) })({
	key: apiKey,
	v: "weekly"
});

const buildMap = (getResultsFirst) => {
	let boundsZoomed = false;

	const buildMapProcess = () => {
		data = document.querySelector('.js-search-map-results');
		data = data?.textContent || null;

		if (!data.length) {
			defaultLocation();
		}
		else {
			const newBounds = populateMarkers(map, data);
			clusterMarkers();
			zoomBounds(newBounds);
			boundsZoomed = true;
		}

		if (!boundsZoomed) {
			zoomBounds(bounds);
		}
	}

	if (getResultsFirst) {
		getMapResults().then(() => buildMapProcess)
	}
	else {
		buildMapProcess()
	}
}

async function init() {
	const { Map } = await google.maps.importLibrary("maps");
	const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");

	if (!document.getElementById('gmapsclusterer')) {
		const s = document.createElement("script");
		s.type = "text/javascript";
		s.id = "gmapsclusterer";
		s.async = true;
		s.src = "https://unpkg.com/@googlemaps/markerclusterer/dist/index.min.js";
		document.getElementsByTagName("head")[0].appendChild(s);
	}

	advancedMarker = AdvancedMarkerElement;
	map = new Map(document.getElementById('search-map'), {
		zoom: 4,
		minZoom: 2,
		mapTypeControlOptions: {
			style: google.maps.MapTypeControlStyle.DEFAULT,
			position: google.maps.ControlPosition.TOP_RIGHT
		},
		mapId: '677432e3530c420d'
	});
	map.addListener('dblclick', function() {
		closeMapOverlay();
	});

	Popup = PopupOverlay(); // Initialize once here

	window.site.body.on('tabChange',
		function(e) {
			if (e.tabTarget === '#search-map-tab') {
				closeMapOverlay()
				buildMap(true)
			}
		})

	window.site.body.on('resultsUpdate', function() { // When filters are updated in Dynamic-Search.js
		closeMapOverlay()
		buildMap(false)
	});
	window.site.body.on('closeMapOverlay', closeMapOverlay); // JS Event from overlay-popup.js
	window.site.body.on('click', '.js-map-outside-overlay-close', closeMapOverlay)
	window.site.body.on('click', '.js-map-outside-overlay', function(e) {
		if (popupVisible) {
			if ($(e.target).closest('.js-map-outside-overlay-content').length) {
				return;
			}

			closeMapOverlay();
		}
	});
}

function closeMapOverlay() {
	if (currentPopup) {
		currentPopup.setMap(null);
	}

	if (currentPopupMarker) {
		setMarkerIcon(currentPopupMarker, true);
	}

	$('.js-map-outside-overlay').remove();
	window.site.html.removeClass(overlayOpenCSSClass);

	map.set('draggable', true);
	popupVisible = false;
}

function populateMarkers(map, data) {
	markers.forEach(marker => marker.setMap(null));
	markers = [];
	if (markerCluster) {
		markerCluster.clearMarkers();
	}

	try {
		data = JSON.parse(data);
	}
	catch (e) {
		console.log(`Could not parse JSON location data for maps, ${e}`)
	}

	const bounds = new google.maps.LatLngBounds();
	data.forEach(function(result) {
		addMarker(map, result);

		bounds.extend({
			lat: result.Latitude,
			lng: result.Longitude
		});
	});

	return bounds;
}

function addMarker(map, data) {
	const content = document.createElement("div");
	content.innerHTML = `<div class="c-search-map__icon-wrapper">
		<div class="c-search-map__icon-wrapper__img"><img src="${openPin}" /></div>
		<div class="c-search-map__icon-wrapper__img"><img src="${closePin}" /></div>
	</div>`;

	const marker = new google.maps.marker.AdvancedMarkerElement({
		position: {
			lat: data.Latitude,
			lng: data.Longitude
		},
		content,
		map
	});

	marker.addListener('click', () => {
		closeMapOverlay();

		setMarkerIcon(marker);
		getPropertyInformation(data, marker);
	});

	markers.push(marker);
}

function setMarkerIcon(marker, close) {
	marker.content.classList[close ? 'remove' : 'toggle']('map-icon-open')
}

function buildPopup(data, propertyInfo, marker) {
	if (popupVisible) {
		// If theres one already open, close old first
		closeMapOverlay()
	}

	currentPopupMarker = marker;
	map.panTo({
		lat: data.Latitude,
		lng: data.Longitude
	});
	google.maps.event.addListenerOnce(map, 'idle', () => {
		// Wait for Panning to finish before opening Popup

		if (document.fullscreenElement) {
			// Fullscreen mode - show Google Popup/overlay
			currentPopup = new Popup({
				lat: data.Latitude,
				lng: data.Longitude
			}, propertyInfo, "property");

			currentPopup.setMap(map);

			map.set('draggable', false);
		}
		else {
			// Show div panel attached to #search-map
			$('.js-search-map-container').append(propertyInfo);
			window.site.html.addClass(overlayOpenCSSClass);
		}

		helpPinInit();
	})

	popupVisible = true;
}

function zoomBounds(bounds) {
	bounds && map.fitBounds(bounds);
}

function clusterMarkers() {
	if (!markerCluster) {
		const config = {
			map,
			markers,
			algorithm: new markerClusterer.SuperClusterAlgorithm({ maxZoom: 12, radius: 80, minPoints: 0 }),
			renderer: {
				render: ({ count, position }, stats, map) => {

					const content = document.createElement("div");
					content.innerHTML = `<div class="c-search-map__icon-wrapper">
						<div class="c-search-map__icon-wrapper__img"><img src="${clusterPin}" /></div>
						<span class="c-search-map__icon-wrapper__text">${count}</span>
					</div>`;

					const clusterOptions = {
						map,
						position,
						zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
						content,
					};

					return new google.maps.marker.AdvancedMarkerElement(clusterOptions);
				}
			}
		}

		markerCluster = new markerClusterer.MarkerClusterer(config)
	}
	else {
		markerCluster.addMarkers(markers);
	}
}

function defaultLocation() {
	const europe = { lat: 54.5260, lng: 15.2551 };

	map.setZoom(4);
	map.setCenter(europe);
}

function getPropertyInformation(data, marker) {
	showLoader();

	$.ajax({
		url: '/api/map-node?propertyId=' + data.Id + '&' + getAllSearchParameters(),
		dataType: 'html',
		success: function(response) {
			buildPopup(data, response, marker);
		},
		error: function(e) {
			console.log(`getPropertyInformation failed: ${e}`)
		},
		complete: function() {
			hideLoader();
		}
	});
}

function getAllSearchParameters() {
	const presetSearchParameters = $('.js-search-map-container').attr('data-map-node-preset-querystring');

	return presetSearchParameters ? presetSearchParameters : window.location.search.substr(1);
}

window.addEventListener("fullscreenchange", () => {
	window.site.html.toggleClass('fullscreenOverlayOpen')
});

export default init;
export { closeMapOverlay }
